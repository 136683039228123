import {type SEOHandle} from '@nasa-gcn/remix-seo';
import {Link} from '@remix-run/react';
import {Container} from '#/app/components/Container.jsx';
import {Image} from '#/app/components/Image.jsx';
import {TalkToUsButton} from '#/app/components/TalkToUsButton.jsx';

export const handle: SEOHandle = {
  getSitemapEntries: () => null,
};

export default function NotFound() {
  return (
    <Container>
      <div className="flex flex-col-reverse items-center justify-between py-8 sm:flex-row">
        <div className="">
          <div className="mb-12 space-y-8">
            <h2 className="whitespace-pre-wrap">Page Not Found</h2>
            <p className="ml-0.5 whitespace-pre-wrap text-xl leading-relaxed text-gray-500 sm:text-2xl">
              Sorry, but the page you are looking for could not be found.
            </p>
            <p className="ml-0.5 whitespace-pre-wrap text-xl leading-relaxed text-gray-500 sm:text-2xl">
              You can return to our
              {' '}
              <Link className="text-sky-500 underline hover:text-sky-600" to="/">
                front page
              </Link>
              , or if you can't find what you're looking for:
            </p>
            <div className="flex">
              <TalkToUsButton appearance="primary" color="sky" />
            </div>
          </div>
        </div>
        <div className="mx-auto flex w-10/12 shrink-0 items-center justify-center pb-10 sm:w-7/12 sm:pb-0 sm:pl-24">
          <Image alt={`404`} src="/features-driver-app.jpg" />
        </div>
      </div>
    </Container>
  );
}
